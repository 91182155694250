<template>
    <div class="user-member-list">
        <div class="search-toolbar">
            <div class="tool-item">
                <span class="item-label">所属职位：</span>
                <el-select v-model="searchForm.roleId" class="item-select" placeholder="请选择">
                    <el-option
                        v-for="(item,index) in roleList"
                        :key="item.id"
                        :label="item.title"
                        :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div class="tool-item tool-buttons">
                <el-button type="primary" @click="getMemberList()">搜索</el-button>
                <el-button @click="clearSearchForm()">重置</el-button>
            </div>
        </div>
        <div v-loading="memberListLoading" class="table-container">
            <div class="buttons-wrapper">
                <el-button type="primary" @click="openAddDialog()">新建</el-button>
                <el-button type="danger" @click="openDelDialog()">删除</el-button>
            </div>
            <div class="table-wrapper">
                <el-table
                    :cell-style="{textAlign:'center'}"
                    :data="memberList"
                    :header-cell-style="{background:'#409EFF',color:'#fff',textAlign:'center'}"
                    @selection-change="getMemberSelection"
                >
                    <el-table-column
                        type="selection"
                        width="55">
                    </el-table-column>
                    <el-table-column
                        label="员工姓名"
                        prop="realname"
                    >
                    </el-table-column>
                    <!-- <el-table-column-->
                    <!--     label="部门"-->
                    <!-- >-->
                    <!--     <template #default="scope">-->
                    <!--         <span>-->
                    <!--             {{-->
                    <!--                 scope.row.roles.length > 0 && scope.row.roles[0].department ? scope.row.roles[0].department.title : null-->
                    <!--             }}-->
                    <!--          </span>-->
                    <!--     </template>-->
                    <!-- </el-table-column>-->
                    <el-table-column
                        label="职位"
                    >
                        <template #default="scope">
                            <span> {{ scope.row.roles.length > 0 ? scope.row.roles[0].title : null }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="手机号"
                        prop="phone"
                    >
                    </el-table-column>
                    <el-table-column
                        label="邮箱"
                        prop="email"
                    >
                    </el-table-column>
                    <el-table-column
                        label="注册时间"
                        prop="createdAt"
                    >
                    </el-table-column>
                    <el-table-column
                        label="操作"
                        width="100"
                    >
                        <template #default="scope">
                            <el-button size="mini" @click="openDetailDialog(scope.row.id)">详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="pagination-wrapper">
                <el-pagination
                    :current-page="page.page"
                    :page-size="page.size"
                    :total="memberTotal"
                    background
                    layout="sizes,prev, pager, next,jumper"
                    @current-change="changePageCurrent"
                    @size-change="changePageSize"
                >
                </el-pagination>
            </div>
        </div>
    </div>
    <!--查看/编辑弹窗-->
    <UserMemberDetail
        :id="detailDialog.id"
        v-model:show="detailDialog.show"
        @updateList="getMemberList"
    >
    </UserMemberDetail>
    <!--新增弹窗-->
    <UserMemberAdd v-model:show="addDialog.show" @updateList="getMemberList"></UserMemberAdd>
</template>

<script>
import {onMounted, reactive, toRefs} from "vue";
import {ElMessage, ElMessageBox} from "element-plus";
import UserMemberAdd from "./UserMemberAdd";
import UserMemberDetail from "./UserMemberDetail";
import {DEL_USERS, GET_ROLES_LIST, GET_USER_LIST} from "../../../api/dataProvider";

export default {
    name: "UserMemberList",
    components: {
        UserMemberAdd,
        UserMemberDetail
    },
    setup() {
        let state = reactive({
            memberListLoading: false,
            // 搜索条件
            searchForm: {
                roleId: "",
            },
            // 角色列表
            roleList: [],
            // 用户列表
            memberList: [],
            memberSelection: [],
            // 分页信息
            memberTotal: 0,
            page: {
                page: 1,
                size: 10,
            },
            // 详情弹窗
            addDialog: {
                show: false,
            },
            // 详情弹窗
            detailDialog: {
                show: false,
                id: ""
            }
        })

        onMounted(() => {
            getRoleList()
            getMemberList()
        })

        // 获取职位列表
        let getRoleList = () => {
            GET_ROLES_LIST(
                {
                    size: -1
                },
                (res) => {
                    if (res.data.status === 200) {
                        state.roleList = res.data.data
                    } else {
                        ElMessage({
                            message: "列表获取失败，请刷新重试！",
                            type: "error"
                        });
                    }
                })
        }

        // 重置搜索
        let clearSearchForm = () => {
            state.searchForm = {
                roleId: "",
            }
        }

        // 获取用户列表
        let getMemberList = () => {
            state.memberListLoading = true
            GET_USER_LIST(
                {
                    ...state.searchForm,
                    ...state.page
                },
                (res) => {
                    if (res.data.status === 200) {
                        state.memberTotal = res.data.page.total
                        state.memberList = res.data.data
                        console.log(res.data.data)
                    } else {
                        ElMessage({
                            message: "列表获取失败，请刷新重试！",
                            type: "error"
                        });
                    }
                    state.memberListLoading = false
                })
        }

        // 修改分页当前页
        let changePageCurrent = (page) => {
            state.page.page = page
            getMemberList()
        }

        // 修改分页size
        let changePageSize = (size) => {
            state.page.size = size
            getMemberList()
        }

        // 获取列表选中id列表
        let getMemberSelection = (arr) => {
            let idArr = []
            arr.map((item) => {
                idArr.push(item.id)
            })
            state.memberSelection = idArr
        }

        // 打开删除弹窗
        let openDelDialog = (item) => {
            console.log(state.memberSelection)
            ElMessageBox.confirm("此操作将永久删除选中职员, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                DEL_USERS(
                    {
                        id: state.memberSelection
                    },
                    (res) => {
                        if (res.data.status === 200) {
                            ElMessage({
                                type: "success",
                                message: "删除成功!"
                            });
                            getMemberList()
                        } else {
                            ElMessage({
                                type: "error",
                                message: "删除失败！"
                            });
                        }
                    })
            })
        }

        // 打开新增弹窗
        let openAddDialog = () => {
            state.addDialog.show = true
        }

        // 打开详情弹窗
        let openDetailDialog = (id) => {
            state.detailDialog.id = id
            state.detailDialog.show = true
        }

        return {
            ...toRefs(state),
            clearSearchForm,
            getRoleList,
            getMemberList,
            changePageCurrent,
            changePageSize,
            openAddDialog,
            openDetailDialog,
            getMemberSelection,
            openDelDialog
        }
    },
}
</script>

<style lang="scss" scoped>
.user-member-list {

}
</style>