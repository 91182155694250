<template>
    <el-dialog
        v-model="show"
        title="员工详情"
        width="700px"
    >
        <div v-loading="loading" class="user-member-dialog">
            <el-tabs>
                <el-tab-pane label="基本信息">
                    <div class="basic-info">
                        <el-form label-position="left" label-width="100px">
                            <el-row :gutter="20">
                                <!--<el-col v-if="memeberDetail.roles&&memeberDetail.roles[0].department" :span="12">-->
                                <!--    <el-form-item label="所属部门：">-->
                                <!--        <el-select-->
                                <!--            v-model="memeberDetail.roles[0].department.id"-->
                                <!--            placeholder="请选择所属部门"-->
                                <!--        >-->
                                <!--            <el-option-->
                                <!--                v-for="(item,index) in departmentList"-->
                                <!--                :key="index"-->
                                <!--                :label="item.title"-->
                                <!--                :value="item.id"-->
                                <!--            >-->
                                <!--            </el-option>-->
                                <!--        </el-select>-->
                                <!--    </el-form-item>-->
                                <!--</el-col>-->
                                <el-col v-if="memeberDetail.roles" :span="12">
                                    <el-form-item label="所属职位：">
                                        <el-select
                                            v-model="memeberDetail.roles[0].id"
                                            placeholder="请选择所属职位"
                                        >
                                            <el-option
                                                v-for="(item,index) in roleList"
                                                :key="index"
                                                :label="item.title"
                                                :value="item.id"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="员工姓名：">
                                        <el-input v-model="memeberDetail.realname" placeholder="请输入员工姓名"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="手机号：">
                                        <el-input v-model="memeberDetail.phone" placeholder="请输入手机号"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="邮箱：">
                                        <el-input v-model="memeberDetail.email" placeholder="请输入邮箱"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="登录密码：">
                                        <el-input v-model="password" placeholder="请输入登录密码" type="password"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="辅助信息">
                    <div class="other-info">
                        <el-form>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item label="员工ID：">{{ memeberDetail.id }}</el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="更新时间：">{{ memeberDetail.updatedAt }}</el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="创建时间：">{{ memeberDetail.createdAt }}</el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
        <template v-if="!loading" #footer>
            <el-button @click="show = false">取消</el-button>
            <el-button type="primary" @click="edit">修改</el-button>
        </template>
    </el-dialog>
</template>

<script>
import {onMounted, reactive, toRefs, watch} from "vue";
import {GET_DEPARTMENTS_LIST, GET_ROLES_LIST, GET_USER_DETAIL} from "@/api/dataProvider";
import {UPDATE_USER_DETAIL} from "../../../api/dataProvider";
import {ElMessage} from "element-plus";

export default {
    name: "UserMemberDetail",
    props: {
        show: Boolean,
        id: "",
    },
    emits: ["updateList", "update:show"],
    setup(props, context) {
        let state = reactive({
            loading: false,
            departmentList: [],
            roleList: [],
            memeberDetail: {},
            password: "",
        })

        onMounted(() => {
            if (props.id) {
                init()
            }
        })

        watch(() => props.id, (val) => {
            if (val) {
                init()
            }
        })

        watch(() => props.show, (val) => {
            context.emit("update:show", val);
        })

        // 数据操作
        let init = () => {
            state.loading = true
            getDepartmentList().then((res) => {
                return getRoleList()
            }).then((res) => {
                return getMemberDetail()
            }).then((res) => {
                state.loading = false
            })
        }

        // 获取部门列表
        let getDepartmentList = () => {
            return new Promise((resolve) => {
                GET_DEPARTMENTS_LIST(
                    {
                        size: -1
                    },
                    (res) => {
                        if (res.data.status === 200) {
                            state.departmentList = res.data.data
                        }
                        resolve()
                    })
            })
        }

        // 获取职位列表
        let getRoleList = () => {
            return new Promise((resolve) => {
                GET_ROLES_LIST(
                    {
                        size: -1
                    },
                    (res) => {
                        if (res.data.status === 200) {
                            state.roleList = res.data.data
                        }
                        resolve()
                    })
            })
        }

        // 获取用户列表
        let getMemberDetail = () => {
            return new Promise((resolve) => {
                GET_USER_DETAIL(props.id, (res) => {
                    if (res.data.status === 200) {
                        state.memeberDetail = res.data.data
                    } else {
                        ElMessage({
                            message: "数据获取失败，请刷新重试！",
                            type: "error"
                        });
                    }
                    resolve()
                })
            })
        }

        // 编辑
        let edit = () => {
            UPDATE_USER_DETAIL(
                {
                    id: state.memeberDetail.id,
                    roleId: [state.memeberDetail.roles[0].id],
                    username: state.memeberDetail.username,
                    realname: state.memeberDetail.realname,
                    phone: state.memeberDetail.phone,
                    email: state.memeberDetail.email,
                    password: state.password,

                },
                (res) => {
                    if (res.data.status === 200) {
                        ElMessage({
                            type: "success",
                            message: "修改成功!"
                        });
                        updateList()
                    } else {
                        ElMessage({
                            type: "error",
                            message: "修改失败，请重试！"
                        });
                    }
                }
            )
        }

        // 更新父级列表
        let updateList = () => {
            context.emit("updateList");
            context.emit("update:show", false);
        }

        return {
            ...toRefs(state),
            init,
            getDepartmentList,
            getRoleList,
            getMemberDetail,
            edit,
            updateList
        }
    },
}
</script>

<style lang="scss" scoped>
.user-member-dialog {
    .el-form {
        .el-form-item {
            .el-select {
                width: 100%;
            }
        }
    }
}
</style>